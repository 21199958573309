import React from "react";

import Layout from "../components/layout";
import resources from '../images/resources.png';

function ResourcesPage() {
  return (
    <Layout name="resources">
      <div className="text-gray-500 flex flex-col md:flex-row">
        <div className="w-full md:w-2/5 h-1/2 md:min-h-full p-2">
          <div className="flex content-center items-center overflow-hidden">
            <img className="flex-shrink-0 min-w-full min-h-full" src={resources} />
          </div>
        </div>
        <div className="w-full md:w-3/5 p-2">
          <section className="pr-4">
            <h2 className="text-4xl mb-8 leading-loose">
              Recursos Físicos
            </h2>
          </section>
          <div className="leading-loose ml-2 divide-y">
            <div>
              <p className="text-xl mt-2">O jardim/creche S.Vicente é um edifício construído de raiz que dispões das seguintes infra-estruturas:</p>
              <ul className="list-disc list-inside ml-2">
                <li>Recepção</li>
                <li>Gabinete da Directora</li>
                <li>Sala dos educadores e área de isolamento</li>
                <li>Sala polivalente e refeitório</li>
                <li>Átrio de Serviço</li>
                <li>Arrumos de produtos de limpeza</li>
                <li>Arrecadações/Despensa</li>
                <li>Despensa/zona de frios</li>
                <li>Cozinha</li>
                <li>Instalações sanitárias para pessoal</li>
                <li>Pátio/Recreio</li>
              </ul>
            </div>
            <div className="divide-y">
              <div className="mt-2">
                <p className="text-xl">Creche:</p>
                <ul className="list-disc list-inside ml-2 leading-loose">
                  <li>Berçário (0-1 ano)</li>
                  <ul className="list-none list-inside ml-2 text-sm">
                    <li>Copa dos Leites</li>
                    <li>Zona de higienização</li>
                    <li>Sala de berços</li>
                    <li>Sala parque</li>
                  </ul>
                  <p className="ml-2 text-sm italic">Número máximo de crianças: 8</p>
                  <li>Sala de 1 ano (1-2 ano)</li>
                  <ul className="list-none list-inside ml-2 text-sm">
                    <li>Sala de actividades</li>
                    <li>Zona de higienização</li>
                    <li>Zona de Bacios</li>
                  </ul>
                  <p className="ml-2 text-sm italic">Número máximo de crianças: 10</p>
                  <li>Sala dos 2 anos (2-3 anos)</li>
                  <ul className="list-none list-inside ml-2 text-sm">
                    <li>Sala de actividades</li>
                    <li>Instalações sanitárias</li>
                  </ul>
                  <p className="ml-2 text-sm italic">Número máximo de crianças: 15</p>
                </ul>
              </div>
              <div className="mt-2">
                <p className="text-xl">Infantário:</p>
                <ul className="list-disc list-inside ml-2 leading-loose">
                  <li>Sala dos 3 aos 5 anos</li>
                  <ul className="list-none list-inside ml-2 text-sm">
                    <li>Sala de actividades</li>
                    <li>Instalações sanitárias</li>
                    <li>Instalações sanitárias para crianças com mobilidade reduzida</li>
                  </ul>
                  <p className="ml-2 text-sm italic">Número máximo de crianças: 25</p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ResourcesPage;
